<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          Thông báo tổ chức hoạt động xúc tiến đầu tư không sử dụng ngân sách nhà nước
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="6">
              <CForm>
                <CInput label="Số văn bản" horizontal :value.sync="filterModel.soVanBan"/>
              </CForm>
            </CCol>
            <CCol sm="3" style="display: flex">
              <label>Từ ngày</label>
              <v-date-picker class="ml-5" v-model="filterModel.tuNgay" locale="vi">
                <template v-slot="{ inputValue, inputEvents }">
                  <CInput
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
            </CCol>
            <CCol sm="3" style="display: flex">
              <label>Đến ngày</label>
              <v-date-picker class="ml-5" v-model="filterModel.denNgay" locale="vi">
                <template v-slot="{ inputValue, inputEvents }">
                  <CInput
                    :value="inputValue"
                    v-on="inputEvents"
                    :placeholder="filterModel.denNgay"
                  />
                </template>
              </v-date-picker>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6">
              <CSelect label="Trạng thái" horizontal :options="optionsTrangThai" :value.sync="filterModel.trangThai"/>
            </CCol>
            <CCol lg="6">
              <CInput label="Tên hoạt động" horizontal :value.sync="filterModel.tenHoatDong"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12">
              <CButton class="mb-3 mr-3" color="primary" :disabled="isFetchingList" @click="search">
                <CIcon name="cil-search"/>&nbsp;Tìm kiếm
              </CButton>
              <CButton class="mb-3" color="primary" @click="createNew" v-show="showCreate">
                <CIcon name="cil-plus"/>&nbsp;Thêm mới
              </CButton>
            </CCol>
          </CRow>
          <div class="position-relative table-responsive">
            <table id="tbThongBaoKeHoach" class="table table-striped table-fixed table-hover table-bordered">
              <thead>
              <tr>
                <th class="position-relative pr-4">STT</th>
                <th class="position-relative pr-4">Số văn bản</th>
                <th class="position-relative pr-4">Tên hoạt động</th>
                <th class="position-relative pr-4">Ngày gửi</th>
                <th class="position-relative pr-4">Tình trạng</th>
                <th class="position-relative pr-4">Số lần hiệu chỉnh</th>
                <th class="position-relative pr-4">Trạng thái</th>
                <th class="position-relative pr-4">Thao tác</th>
              </tr>
              </thead>
              <tbody class="position-relative">
              <template v-if="dataSourcePaging.items">
                <tr v-for="(item, index) in dataSourcePaging.items" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.soVanBan }}</td>
                  <!--                  <td>{{ displayLabel(optionsLoaiDonVi, item.loaiDonVi) }}</td>-->
                  <td>{{ item.tenHoatDong }}</td>
                  <td>{{ formatDateData(item.createdDate) }}</td>
                  <td>{{ item.trangThai }}</td>
                  <td>{{ item.soLanHieuChinh }}</td>
                  <td>
                    <template v-if="item.trangThai === 0">Tất cả</template>
                    <template v-if="item.trangThai === 1">Soạn thảo</template>
                    <template v-if="item.trangThai === 2">Chờ phê duyệt</template>
                    <template v-if="item.trangThai === 3">Đã phê duyệt</template>
                  </td>
                  <td>
                    <CButton size="sm" color="info" class="mr-2" @click="viewItem(item)" title="Chi tiết" v-show="showDetail">
                      <CIcon name="cil-options"/>
                    </CButton>
                    <CButton size="sm" color="primary" class="mr-2" v-if="item.trangThai != 3 && showUpdate" @click="updateItem(item)" title="Chỉnh sửa">
                      <CIcon name="cil-pencil"/>
                    </CButton>
                    <CButton size="sm" color="secondary" class="mr-2" v-if="item.trangThai == 2 && showApprove" @click="confirmApproveItem(item)" title="Phê duyệt">
                      <CIcon name="cil-settings"/>
                    </CButton>
                    <CButton size="sm" color="danger" @click="destroyItem(item)" title="Xóa" v-if="showDelete">
                      <CIcon name="cil-x"/>
                    </CButton>
                  </td>
                </tr>
              </template>
              <tr v-if="dataSourcePaging.items && dataSourcePaging.items.length === 0 && !isFetchingList">
                <td colspan="8">
                  <slot name="no-items-view">
                    <div class="text-center my-5">
                      <h2>
                        Không có dữ liệu
                        <CIcon width="30" name="cil-ban" class="text-danger mb-2"/>
                      </h2>
                    </div>
                  </slot>
                </td>
              </tr>
              </tbody>
            </table>
            <CElementCover v-if="isFetchingList"
                           :boundaries="[{ sides: ['top'], query: 'td' },{ sides: ['bottom'], query: 'tbody' }]"/>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <DeleteConfirmationModal
      title="Xóa thông báo kế hoạch tổ chức xúc tiến đầu tư không sử dụng ngân sách nhà nước"
      :show.sync="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="item" @confirm-destroy="confirmDestroy"/>
    <ApproveConfirmationModal
      title="Phê duyệt thông báo kế hoạch tổ chức xúc tiến đầu tư không sử dụng ngân sách nhà nước"
      :show.sync="isApproveConfirmationModalOpen"
      :is-approving="isApproving"
      v-on:data="data($event)"
      :type="type"
      :item="approvingItem" @confirm-approve="confirmApprove"/>
  </CRow>
</template>

<script>

import * as types from '@/store/modules/VanBanTBTCHDXTDTKSDNSNN/actionTypes'
import { mapActions, mapGetters } from 'vuex'
import { statuses, displayLabel } from '@/shared/appConstants'
import moment from 'moment'
import { DELETE_THONGBAOTOCHUC, APPROVE_THONGBAOTOCHUC } from '@/store/modules/VanBanTBTCHDXTDTKSDNSNN/actionTypes'

export default {
  name: 'ThongBaoToChuc',
  components: {},
  data () {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
          sorter: true
        },
        {
          key: 'tenLoaiHoatDong',
          label: 'Tên loại hoạt động',
          sorter: true
        },
        {
          key: 'moTa',
          label: 'Mô tả',
          sorter: false
        },
        {
          key: 'trangThai',
          label: 'Trạng thái',
          sorter: true
        },
        {
          key: 'action',
          label: 'Thao tác',
          sorter: false
        }
      ],
      optionsTrangThai: statuses,
      filterModel: {
        soVanBan: '',
        trangThai: 0,
        tuNgay: '',
        denNgay: '',
        tenHoatDong: '',
        orderBy: null,
        pageNumber: 1,
        pageSize: 15
      },
      isApproveConfirmationModalOpen: false,
      isDeleteConfirmationModalOpen: false,
      type: 'VanBanPAXTDTTHDDNCCNC',
      approvingItem: {},
      information: {
        dinhKem: '',
        noiDung: '',
        id: 0
      },
      showCreate: false,
      showUpdate: false,
      showDetail: false,
      showApprove: false,
      showDelete: false
    }
  },
  computed: {
    ...mapGetters('thongBaoToChuc', {
      dataSourcePaging: 'dataSourcePaging',
      isFetchingList: 'isFetchingList',
      // deletedItem: 'deletedItem',
      isDeleting: 'isDeleting',
      // isDeleteConfirmationModalOpen: 'isDeleteConfirmationModalOpen',
      responseResult: 'responseResult',
      isApproving: 'isApproving',
      item: 'detailItem'
    })
  },
  methods: {
    ...mapActions('thongBaoToChuc', {
      getListPaging: types.GET_LIST_THONGBAOTOCHUC_PAGING,
      deleteItem: DELETE_THONGBAOTOCHUC,
      toggleDetailModal: types.TOGGLE_DETAIL_MODAL,
      approveItem: APPROVE_THONGBAOTOCHUC
    }),
    createNew () {
      this.$router.push({ path: 'thong-bao-to-chuc-hd-xtdt/them-moi' })
    },
    viewItem (item) {
      this.$router.push({ path: `thong-bao-to-chuc-hd-xtdt/${item.id}` })
    },
    updateItem (item) {
      this.$router.push({ path: `thong-bao-to-chuc-hd-xtdt/${item.id}/cap-nhat` })
    },
    destroyItem (item) {
      this.deletedItem = Object.assign({}, item)
      this.isDeleteConfirmationModalOpen = true
    },
    confirmApproveItem (item) {
      this.approvingItem = Object.assign({}, item)
      this.isApproveConfirmationModalOpen = true
    },
    async confirmApprove () {
      // console.log(this.approvingItem)
      // console.log('data submit === ', this.information)
      await this.approveItem(this.information)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isApproveConfirmationModalOpen = false
        this.information.noiDung = ''
        this.information.dinhKem = ''
        await this.fetchListItem()
      }
    },
    data (event) {
      this.information.id = event.id
      this.information.noiDung = event.noiDung
      this.information.dinhKem = event.dinhKem
    },
    async confirmDestroy (accept) {
      if (accept) {
        await this.deleteItem(this.deletedItem)
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          this.isDeleteConfirmationModalOpen = false
          await this.fetchListItem()
        }
      }
    },
    formatDateData (v, format = 'YYYY-MM-DD H:i:s', outputFormat = 'DD/MM/YYYY') {
      if (v) {
        return moment(v, format).format(outputFormat)
      } else {
        return ''
      }
    },
    firstItemIndex () {
      return (this.filterModel.pageNumber - 1) * this.filterModel.pageSize || 0
    },
    displayLabel,
    async fetchListItem (accept = true) {
      if (accept) {
        await this.getListPaging(this.filterModel)
      }
    },
    pageChange (page) {
      this.filterModel.pageNumber = page
      this.fetchListItem()
    },
    changeSorterValue (value) {
      this.filterModel.orderBy = value
      this.fetchListItem()
    },
    search () {
      this.filterModel.pageNumber = 1
      this.filterModel.orderBy = null
      this.fetchListItem()
    },
    getRole () {
      const role = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : {}
      const permissions = role.permissions
      if (permissions.includes('VanBanTBTCHDXTDTKSDNSNN_ADD')) {
        this.showCreate = true
      } else this.showCreate = false
      if (permissions.includes('VanBanTBTCHDXTDTKSDNSNN_UPDATE')) {
        this.showUpdate = true
      } else this.showUpdate = false
      if (permissions.includes('VanBanTBTCHDXTDTKSDNSNN_DELETE')) {
        this.showDelete = true
      } else this.showDelete = false
      if (permissions.includes('VanBanTBTCHDXTDTKSDNSNN_APPROVE')) {
        this.showApprove = true
      } else this.showApprove = false
      if (permissions.includes('VanBanTBTCHDXTDTKSDNSNN_VIEW')) {
        this.showDetail = true
      } else this.showDetail = false
    }
  },
  async mounted () {
    await this.getRole()
    await this.fetchListItem()
  }
}
</script>
